.App {
  text-align: center;
}
.App-container {
  max-width: 7570px;
  margin: auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  font-weight: 500!important;
}

.btn-primary{
  background: rgb(41, 156, 219)!important;
}

/*** Authentification **/
.bg-image-auth {
  background-image: url(./assets/Images/auth/authbg.png);
  background-size: cover;
  min-height: 100%;
  width: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* news */
.label {
  display: block;
  font-size: larger;
  color: white;
  padding: 5px;
}

.form {
  display: flex;
  padding-top: 90px;
  box-sizing: content-box;
  justify-content: center;
}
.messages {
  display: flex;
  justify-content: center;
}
.error {
  display: block;
  /* background-color: red; */
  color: red;
  width: fit-content;
  padding: 5px;
}
.success {
  display: block;
  background-color: lightblue;
  color: black;
  width: fit-content;
  height: 50px;
  padding: 5px;
}

/**** Sidebar collapse animation ******/
.sidebar {
  margin-left: -250px;
  transition: margin ease 0.5s;
}
.sidebar.open {
  margin: 0;
  z-index: 1;
}
.sidebar.open nav {
  position: fixed;
}
.sidebar nav {
  position: inherit;
}
.logonav.show {
  display: inherit;
  opacity: 1;
  transition: opacity ease 0.5s;
}
.logonav.hide {
  display: none;
  opacity: 0;
  transition: opacity ease 0.5s;
}

@media (max-width: 1300px) and (min-width: 768px) {
  .sidebar.open {
    margin-right: 20px !important;
  }
}
@media (max-width: 767px) {
  .sidebar.open {
    width: 100% !important;
    position: inherit;
  }
  .logonav.hide.logos {
    display: inherit;
    opacity: 1;
  }
  .sidebar.open nav,
  .sidebar.closed nav {
    max-width: 100% !important;
    position: fixed;
  }
  .logonav.show.blue,
  .logonav.hide.blue {
    display: inherit;
    opacity: 1;
    transition: opacity ease 0.5s;
  }
  .logonav.show.gray {
    display: none;
    opacity: 0;
    transition: opacity ease 0.5s;
  }
  .sidebar.closed {
    margin: 0;
    width: 100% !important;
    position: inherit;
    z-index: 1;
  }
  .sidebar.open.closed {
    margin-left: -100%;
    transition: margin ease 0.5s;
  }
}

/****** pagination ******/

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0;
}

.MuiTablePagination-root .MuiToolbar-root{
  overflow-y: scroll;
}

/**** Layout page *****/
.Layout.large {
  overflow: hidden;
}
@media (max-width: 991px) and (min-width: 909px) {
  .Layout.large nav.navbar {
    max-width: 82%;
  }
}
@media (max-width: 909px) and (min-width: 768px) {
  .Layout.large nav.navbar {
    max-width: 80%;
  }
}
/***** general basic styles *****/
table tr {
  height: 10px;
}
table thead th {
  font-weight: 500!important;
}
table thead tr th {
  padding-block: 10px !important;
  /* padding-left: 0!important; */
}
table thead tr th:first-child,
table tbody tr th:first-child {
  padding-left: 1rem !important;
}
table tbody td:first-child {
  padding: 6px 16px !important;
}
tbody {
  overflow-y: auto;
  overflow-x: hidden;
}
table tr:nth-child(even) {
  background: #f5f5f5;
}
/**** btn *****/
.mapSwitchList {
  top: 50%;
  transform: translate(-100%, -50%);
  letter-spacing: 5px;
  position: fixed;
  /* transition: transform 0.8s ease; */
  z-index: 999;
  width: 50px;
  opacity: 0.6;
  left: 103%;
}

.App-container.px-2 .mapSwitchList.hided,
.App-container.px-4 .mapSwitchList.hided {
  transform: translate(0%, -50%);
  /* transition: transform 0.8s ease; */
  left: auto;
  right: 0;
  opacity: 1;
  left: 98%;
}
.App-container.px-4 .mapSwitchList {
  left: 103%;
}

.mapSwitchList:hover {
  opacity: 1;
}

.mapSwitchList button {
  letter-spacing: 5px;
}

.hided.maps {
  margin-right: -200%;
  transition: margin-right ease-in-out 1s;
  opacity: 0;
  z-index: 0;
}
.normal.maps {
  margin-right: 0;
  transition: margin-right ease-in-out 1s;
  opacity: 1;
}
/**** Utility *****/
.pointer {
  cursor: pointer;
}
.accordionHeading.accordion-header button[aria-expanded="true"] {
  background: #fff;
  box-shadow: none;
  color: #282c34;
  outline: none;
  font-weight: 600;
}
.accordionHeading.accordion-header button[aria-expanded="false"] {
  background: #fff;
  color: #282c34;
  border-bottom: #5c5b5b72 solid 1px;
  border-radius: 0 !important;
  font-weight: 600;
}
@media (max-width: 767px) {
  .h-sm-auto {
    height: auto !important;
  }
  .h-sm-50 {
    height: 50px;
  }
  .h-sm-50 img.h-100 {
    height: auto !important;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .h-md-440 {
    height: 440px;
  }
  .h-md-440 img {
    object-fit: cover;
  }
}
@media (min-width: 576px) {
  .inscription-container .row {
    width: 774px;
  }
}

@media (max-width: 575.98px) {
  .inscription-container .row {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .inscription-container {
    max-width: 500px;
    margin: auto;
  }
  .w-xs-normal {
    width: auto !important;
  }
  .h-md-440 {
    display: none;
  }
  .d-none-xs {
    display: none;
  }
  .h-sm-auto {
    height: 100vh !important;
  }
}
@media (max-width: 1199px) {
  .lg-screens,
  .lg-screens.hided {
    display: none;
  }
  .lg-screens.showed {
    display: block;
  }
}

/***** Forms *****/
.accordion.Filters label.form-label {
  color: #969696;
}
.accordion.Filters select {
  color: #5b5b5b;
}
.accordion.Filters .react-datepicker__input-container {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.accordion.Filters .react-datepicker-wrapper {
  margin-inline: 5px;
}
.accordion.Filters input::-webkit-outer-spin-button,
.accordion.Filters input::-webkit-inner-spin-button {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
}
.accordion.Filters input[type="number"]::after {
  content: "m2";
}

/* Firefox */
.accordion.Filters input[type="number"] {
  -moz-appearance: textfield;
}
.accordion.Filters .react-datepicker__input-container input {
  border: none;
  color: #5b5b5b;
}
.transaction-heading.accordion-header .accordion-button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.transaction-heading.accordion-header .accordion-button::after {
  margin: 0;
}

/***** Table styles *****/
table tr td,
th {
  font-size: 0.9rem;
}
table tbody tr:hover {
  background: rgba(63, 80, 182, 0.1);
}
/**** Mui font styles *****/
h4.MuiTypography-root.MuiTypography-h4.css-5lbw0b-MuiTypography-root {
  font-size: 1.5rem;
}

h5.MuiTypography-root.MuiTypography-h5.css-ag7rrr-MuiTypography-root {
  font-size: 1.25rem;
}
.includedTabs .MuiTabs-flexContainer {
  height: 100% !important;
}
.includedTabs .MuiTabs-flexContainer .Mui-selected {
  color: #fff !important;
  background: #299cdb;
  border-top-right-radius: 8px;
}

/***** tabs *****/
@media (max-width: 899px) {
  .includedTabs .MuiTabs-flexContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/****exclude border ***/
.deleteBorder td {
  border: 0 solid !important;
  background: #d4ebf8;
}

/*** CRM ***/
.crm thead tr th:first-child {
  padding-left: 1rem !important;
}
.crm tbody tr {
  height: 52px !important;
}
.crm tbody tr:hover {
  background: rgba(63, 80, 182, 0.1) !important;
}
.crm tbody tr:nth-child(even) {
  background: #f5f5f5;
}
.email-list {
  width: 100%;
  padding-block: 0 !important;
}
.email-list li {
  padding: 0;
}
.email-list li div:first-child {
  width: auto;
  min-width: inherit;
}
.email-list div.MuiCollapse-root {
  margin-left: 35px;
}

/***** uploader styles ******/
.styledDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.styledDropBox .MuiDropzoneArea-root {
  min-height: 170px;
}
.styledDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer p,
.styledDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer svg {
  text-decoration: underline;
  color: #2196f3;
  font-size: 15px;
}
.styledDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer p {
  margin-top: 0;
}
.styledDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer svg {
  width: 30px;
  margin-top: 20px;
}

.styledSimpleDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  height: 45px;
}
.styledSimpleDropBox .MuiDropzoneArea-root {
  min-height: 50px;
  height: 50px;
}
.styledSimpleDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer p {
  font-size: 15px;
}
.styledSimpleDropBox .MuiDropzoneArea-root .MuiDropzoneArea-textContainer svg {
  color: #2196f3;
  width: 30px;
}
.styledSimpleDropBox {
  width: 48%;
}

/**** Affaires table *****/

.affaire_table tbody:nth-child(odd) {
  background: #f4fafd !important;
}
.affaire_table tbody:nth-child(even) {
  background: #fafafa;
}
.affaire_table tbody tr {
  background: none !important;
}
.affaire_table tbody .TacheCell.TacheCellWithBackground {
  background: #f4fafd !important;
}
.affaire_table tbody .TacheCell {
  background: #fafafa !important;
  padding-bottom: 40px !important;
  padding-inline: 26px !important;
}

/***** Taches table *****/
.TacheCell .MuiAccordionSummary-root {
  flex-direction: row-reverse;
}
.TacheCell .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  width: fit-content !important;
  justify-content: center;
}
/* .TacheCell .MuiCollapse-root *:not(.excludeStyles *) , .TacheCell .MuiCollapse-root {
  width:100%!important;
} */
.TacheCell .MuiCollapse-root .excludedStyles {
  margin: initial;
}

/**** notes *****/
.noteCordion {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
}

/**** toast ****/
.Toastify__toast-container--top-right {
  z-index: 999999 !important;
}

/**** Résumé parcelle ****/
.resume {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 450px;
  z-index: 99999;
  transition: transform ease-in-out 0.5s;
}

.resume .inner {
  height: 100%;
  overflow: scroll;
}
.resume.open {
  right: 0;
  transform: translateX(0%);
  transition: transform ease-in-out 0.5s;
}
.resume.closed {
  right: 0;
  transform: translateX(100%);
  transition: transform ease-in-out 0.5s;
}

@media (max-width: 500px) and (min-width: 380px) {
  .resume {
    max-width: 320px;
    width: 100%;
  }
}
@media (max-width: 380px){
  .resume {
    max-width: 292px;
    width: 100%;
  }
}

/*** default modal z-index ***/
body .MuiModal-root.css-79ws1d-MuiModal-root, .MuiDialog-root.MuiModal-root.css-zw3mfo-MuiModal-root-MuiDialog-root, .MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container {
  z-index: 9999999;
}

/* Style pour le popup de couleur */
[data-popup-position="right"] .color-picker-popup {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 10px;
  z-index: 1000;
}

/*** animation scroll ***/
.slide-panel {
  position: fixed;
  top: 70px;
  right: 21px;
  background: white;
  padding: 20px;
  max-width: 440px;
  width: 100%;
  z-index: 999999;

  /* Animation de base */
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-panel-open {
  transform: translateX(0);
  opacity: 1;
}

.slide-panel-closed {
  transform: translateX(100%);
  opacity: 0;
}

/*** Maps & Tags ***/
.isActived{
  background: rgba(25, 118, 210, 0.04)!important;
}

/*** stickie's heading ***/
 .list-fixed-heading{
   position: sticky;
   top: 0;
   background-color: white;
   z-index: 1000;
   padding: 10px 0;
   margin-bottom: 10px
 }

/* Fix thead on scroll for all tables */
.crm thead tr,
.users-table thead tr,
.tags-table thead tr,
.parcelles-table {
  position: sticky;
  top: 0;
  background: #eeeeee;
  z-index: 1000;
}

.crm thead th,
.users-table thead th,
.tags-table thead th,
.parcelles-table th {
  background: #eeeeee;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Ensure table container has proper scroll */
.overflow-auto,
.table-container {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

/* Add shadow to thead when sticky */
.crm thead tr.sticky,
.users-table thead tr.sticky,
.tags-table thead tr.sticky,
.parcelles-table.sticky {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* MapBox canvas style fix */
.mapboxgl-canvas {
  width: 100% !important;
}